import React from "react";
import styled from "styled-components";
import { GrMailOption, GrPhone } from "react-icons/gr";

const BoxContatos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 4rem 0;
  background-color: #fff;
`;

const BoxMain = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoxText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Mulish, sans-serif;
  max-width: 30rem;
  & h3 {
    color: #3083ff;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.2rem;
    text-transform: uppercase;
  }
  & h4 {
    color: #2d2e32;
    font-family: Poppins, sans-serif;
    font-size: 1.8rem;
    font-weight: 700;
  }
  & p {
    color: #555;
    font-family: Mulish, sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.6;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    & h3 {
      font-size: 1.3rem;
    }
    & h4 {
      font-size: 1.2rem;
    }
    & p {
      font-size: 0.8rem;
    }
  }
  @media (min-width: 481px) and (max-width: 768px) {
    & h3 {
      font-size: 1.3rem;
    }
    & h4 {
      font-size: 1.2rem;
    }
    & p {
      font-size: 0.8rem;
    }
  }
`;

const BoxLocation = styled.div`
  & a {
    display: flex;
    cursor: pointer;
    color: #000;
    text-decoration: none;
    margin-top: 15px;
  }
`;

const BoxMainLocation = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  & h2 {
    font-size: 18px;
  }
`;

const Icon = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  background-color: "#f9f9f9";
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 80px;
`;

export default function Contatos() {
  return (
    <BoxContatos id="contatos">
      <BoxMain>
        <BoxText>
          <h3>Contato</h3>
          <h4>Entre em contato comigo!</h4>
        </BoxText>
        <BoxLocation>
          <a href="mailto:ricardopenga70@gmail.com">
            <Icon>
              <GrMailOption />
            </Icon>
            <BoxMainLocation>
              <h2>Contato</h2>
              <p>ricardopenga70@gmail.com</p>
            </BoxMainLocation>
          </a>
        </BoxLocation>
        {/* <BoxLocation>
          <a href="https://wa.me/5519991972031" target="_blank"
                rel="noreferrer">
            <Icon>
              <GrPhone />
            </Icon>
            <BoxMainLocation>
              <h2>Telefone</h2>
              <p>(19) 99197-2031</p>
            </BoxMainLocation>
          </a>
        </BoxLocation> */}
      </BoxMain>
    </BoxContatos>
  );
}
