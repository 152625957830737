import Contatos from "./components/Contatos";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Projetos from "./components/Projetos";
import Sobre from "./components/Sobre";

function App() {
  return (
    <div className="app">
      <Navbar />
      <Home />
      <Sobre />
      <Projetos />
      <Contatos />
      <Footer />
    </div>
  );
}

export default App;
